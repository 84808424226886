<template>
  <div class="show">
    <el-row>
      <!--      左边-->
      <el-col :span="20">
        <el-row type="flex" justify="space-around">
          <h1 class="article-title">
            {{ article.articleTitle }}
          </h1>
        </el-row>
        <el-row class="article-bar-top">
          <el-col :span="2" class="bar-content">
            {{ article.articleViews }}浏览量
          </el-col>
          <el-col :span="2" class="bar-content"  @click.native="clickGood">
            {{ article.articleGood }}点赞
          </el-col>
          <el-col :span="2" class="bar-content" @click.native="clickCollect">
            {{ article.articleStar }}收藏
          </el-col>
          <el-col :span="2" class="bar-content">
            {{ article.articleBad }}踩
          </el-col>
        </el-row>
        <el-row class="article-bar-top">
          <el-col v-for="(item,index) in tags" :key="index" :span="2">
            <el-tag>
              {{ item }}
            </el-tag>
          </el-col>
        </el-row>

        <el-row class="article-content">
          <el-row  type="flex">
            <el-col :span="4">
              <div
                  v-for="(anchor,index) in titles"
                  :key="index"
                  :style="{ padding: `10px 0 10px ${anchor.indent * 20}px` }"
                  @click="handleAnchorClick(anchor)"
              >
              </div>
            </el-col>
            <el-col :span="20" >
              <mavon-editor v-model="text"
                            :code-style="code_style"
                            :ishljs="true"
                            :toolbarsFlag="false"
                            :subfield="false"
                            :navigation="true"
                            defaultOpen="preview"
              >
              </mavon-editor>
            </el-col>
          </el-row>

        </el-row>
        <el-row>
          <Comment v-if="articleId" :id="articleId" :type="1"></Comment>
        </el-row>
      </el-col>
      <!--      右边-->
      <el-col :span="4">
        <div>{{ article.username }}</div>
        <el-avatar :src="article.userImg"></el-avatar>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {getArticleById} from "@/api/article";
import {good} from "@/api/good";
import {collect} from "@/api/collect";
import {mavonEditor} from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
import 'highlight.js/styles/monokai-sublime.css'

import Comment from "@/components/comment/Comment";
//展示 页面的插件
export default {
  name: "Show2",
  components: {
    Comment,
    mavonEditor
  },
  data() {
    return {
      value: "",
      article: "",
      tags: [],
      articleId: "",
      titles:[],
      text:'',
      code_style: "atom-one-dark",
    }
  },
  mounted() {
    this.change()
  },
  methods: {
    clickGood() {
      console.log("点赞了")
      let go={
        userId:this.$store.state.user.id,
        pid:this.articleId,
        type:1
      }
      good(go).then(result=>{
        this.$message({
          type:"success",
          duration:2*1000,
          message:result.data.good
        })
      }).catch(error=>{
        this.$message({
          type:"error",
          duration:2*1000,
          message:error.message
        })
      })
    },
    // 点击收藏
    clickCollect(){
      let co={
        userId:this.$store.state.user.id,
        pid:this.articleId,
        type:1
      }
      collect(co).then(result=>{
        this.$message({
          type:"success",
          duration:2*1000,
          message:result.data.collect
        })
      }).catch(error=>{
        this.$message({
          type:"error",
          duration:2*1000,
          message:error.message
        })
      })
    },
    // 获取文章
    change() {
      this.articleId = this.$route.query.articleId
      getArticleById(this.articleId).then(result => {
        this.article = result.data.article;
        this.text=result.data.article.articleContext;
        this.tags = this.article.tags;
      }).catch(error => {
        this.$message({
          type: "error",
          message: error.message,
          duration: 2 * 1000
        })
      })
    }
  }
}
</script>

<style scoped >
.pre{
  text-align: left;
}
.article-title {
  width: 90%;
  height: 2.5rem;
  line-height: 2.5rem;
  font-size: 1.75rem;
  word-wrap: break-word;
  color: #222226;
  font-weight: 600;
  margin: 0;
  word-break: break-all;
  text-align: left;
  margin-bottom: 10px;
}

.article-bar-top {
  background-color: #f8f8f8;
  height: 2.5rem;;
  line-height: 2.5rem;
}
.bar-content {
  color: #858585;
}
.bar-content:hover {
  background-color: #ffffff;
  color: black;
}
.markdown-body {
  box-sizing: border-box;
  min-width: 200px;
  max-width: 980px;
  margin: 0 auto;
  padding: 45px;
  text-align: left;
}
.show {
  width: 90%;
  margin: auto;
  height: 100%;
}

</style>
